import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from '../services/localstore.service';
import { AuditLogService } from '../services/audit-log.service';
import { KioskapiService } from '../services/kioskapi.service';
import { ClaimModalPage } from '../claim-modal/claim-modal.page';
import * as moment from 'moment';
import { IonIntlTelInputModel } from 'ion-intl-tel-input';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonIntlTelInputValidators } from 'ion-intl-tel-input';



@Component({
  selector: 'app-name-modal',
  templateUrl: './name-modal.page.html',
  styleUrls: ['./name-modal.page.scss'],
})

export class NameModalPage implements OnInit {
  key = 'Name';
  username;
  email_address;
  error = '';
  name: any;
  valid_email;
  email_valid = 0;
  phone_number;
  phone_valid;
  country_code = '65';

  params : any;
  master_menu_type:any;
  addOnPrice: any = {};
  addonsList = [];
  item: any = [];
  radioAddons = {}; 
  selected_addons = [];
  formFields: any ={};
  addons: Array<string> = [];
  quantity = 1;
  total: number;
  itemprice: number = 0;
  itembaseprice: number = 0;
  total_base_price: number;
  totalAddonPriceArray: any=[];
  totalQuantity: any=[];
  currency = "¥";
  showDecimal= true;
  bookmark_customization: any = {};
  requestFromCombo: boolean = false;
  totalvalue:number;
  totalAmount
  cartItems_create_order:any = [];
  attempted;
  ServiceID
  balance
  deducted
  order_UUID
  isPaymentFailed
  app_settings
  proc_card_idi
 clickCounter = 0;
 redirection = ''
 addons_select;
 addon_array =[];
 thankYou_alert:any;
 mobile_redirection_url =""
 phone: IonIntlTelInputModel = {
  dialCode: '+65',
  internationalNumber: '',
  isoCode: 'sg',
  nationalNumber: ''
};
form: FormGroup;
formValue = {phoneNumber: this.phone, test: ''};
 defaultCountryIsoTest = 'sg';
  dialCodePrefix = '+';
  enableAutoCountrySelect = true;
  enablePlaceholder = false;
  fallbackPlaceholder = '';

  inputPlaceholder = '';

  maxLength = '15';
  modalTitle = 'Select Country';
  modalCssClass = '';
  modalSearchPlaceholder = 'Enter country name';
  modalCloseText = 'X';
  modalFlagButtonSlot = 'start';
  modalCloseButtonSlot = 'start';
  modalCanSearch = true;
  modalShouldBackdropClose = true;
  modalShouldFocusSearchbar = true;
  modalSearchFailText = 'No countries found.';
  onlyCountries = [];
  preferredCountries = ["sg", "jp",];
  selectFirstCountry = ["sg"];
  mobile_form_validation=-1;
  separateDialCode = true;
  internationalNumber;
  selected_item_uuid;
  orderSubmittedSuccess = false;
  isCoverageAccepted;

  constructor(
    public modalCtrl: ModalController,
    private store: LocalStoreService,
    public alertController: AlertController,
    public modalController: ModalController,
    public auditlogServices: AuditLogService,
    private kiosk_apiService : KioskapiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    
  }

  closeModal() {
    this.modalCtrl.dismiss('dismiss');
    this.store.setUserCart([]);
  }


  checkout_orderCreate() {

    if (this.username.length > 12) {
      this.error = 'max_length_error';
      return false;
    } else {
      this.error = '';
      var myObject1 = {
        user_name: this.username,
        email_address: this.email_address,
        international_number: this.internationalNumber,
      }
      this.store.set("myObject", myObject1);
      // this.claimModal();
      this.create_kiosk_order();
      // this.modalCtrl.dismiss(this.username);
    }
  }

  validate_email(emailString) {
    if (this.emailValidator(emailString)) {
      console.log()
      this.valid_email = emailString;
      this.email_valid = 1;
    } else {
      this.email_valid = 0;
    }  
  }

  emailValidator(inputEmail) {
    console.log("Here is my EMail emailValidator", inputEmail);
    inputEmail = inputEmail.trim();
    var emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!emailReg.test(inputEmail)) {
      return false;
    }
    else {
      return inputEmail;
    }
  }

  validate_mobile_number(obj){
    this.mobile_form_validation = -1;

      var mobile = obj?.nationalNumber;
      var internationalNumber = obj?.internationalNumber;
      var code= obj?.dialCode;

      var internationalNumber = internationalNumber?.replace('/\s/g', '');
      var internationalNumber = internationalNumber?.replace(' ', '');
      internationalNumber = internationalNumber?.replace( ' ', '');
      internationalNumber = internationalNumber?.replace('-', '');
      internationalNumber = internationalNumber?.replace('-', '');
      internationalNumber = internationalNumber?.replace('-', '');

      mobile = mobile?.replace(" ","");
      this.internationalNumber = internationalNumber;
      console.log(" internationalNumber ", this.internationalNumber);
      if( mobile?.toString().length ==0){
        this.mobile_form_validation = 0;
      }
      else if( mobile?.toString().length <7 ){
        this.mobile_form_validation = 0;
      }
      else if( mobile?.toString().length >15 ){
        this.mobile_form_validation = 0;        
      }
      else if( mobile?.toString().length >0  &&  mobile?.toString().length <16 ){
        this.mobile_form_validation = 1;
      }
  }

  ionViewWillEnter() {
    // this.name = JSON.parse(window.localStorage.getItem('myObject'));
    // this.username = this.name?.user_name;
    // this.email_address = this.name?.email_address;
    // this.phone_number = this.name?.phone_number;
    // this.country_code = this.name?.country_code;
    // if (this.email_address) {
    //   this.validate_email(this.email_address)
    // }
    this.selected_item_uuid = this.route.snapshot.queryParamMap.get('id');
    this.isCoverageAccepted = this.route.snapshot.queryParamMap.get('isCoverageAccepted');
    this.kiosk_apiService.audit_log("NAME_MODAL");
    this.kiosk_apiService.logEvents("NAME_MODAL")
    this.app_setting();
    this.form = new FormGroup({
      phoneNumber: new FormControl({
        value: /* null */this.formValue.phoneNumber,
        // disabled: this.disableTest
      }, [
        Validators.required,
        IonIntlTelInputValidators.phone
      ])
    });
  }

  isClaimedOrder = 'no';
  app_setting() {
    this.kiosk_apiService.restuarant_setting()
      .subscribe(
        (res) => {
          this.app_settings = res.aaData.module_permissions
          this.mobile_redirection_url = res.aaData.module_permissions.mobile_kiosk_order_redirection
          this.store.set('redirection_link', this.mobile_redirection_url)
        },
        error => {
        }
      );
  }

  clearCart() {
    this.store.setUserCart([]);
    this.router.navigateByUrl(`product-detail?id=` + this.selected_item_uuid);
  }
  
  async claimModal() {
    const modal = await this.modalController.create({
      component: ClaimModalPage,
      cssClass: 'my-claim-class',
      backdropDismiss: false
    });
    modal.onDidDismiss().then((modelData) => {

      console.log('modelData', modelData)
      if (modelData.data != 'dismiss') {
        this.isClaimedOrder = modelData.data;
        this.create_kiosk_order();
      }
      else{
        this.clickCounter = 0
      }
    });
    return await modal.present();
  }

  create_kiosk_order() {
    let cartDetail = this.store.getUserCart();
    this.totalAmount = 0;
    this.cartItems_create_order = [];
    Object.entries(cartDetail).forEach(([k, v]) => {
      Object.entries(v).forEach(([key, value]) => {
        if (v['total']) {
          this.totalAmount = v['total'] + +this.totalAmount;
        }
        this.cartItems_create_order.push(value);
      })
    });
    this.name = JSON.parse(window.localStorage.getItem('myObject'));
    let user_info = JSON.parse(window.localStorage.getItem('user_details'));
  
    let order_type = "TAKE_AWAY";
    if (this.store.get("order_type") == "Dine In") {
      order_type = "DINE_IN";
    }
    let totalCartAmount =  this.totalAmount;
    let user = this.store.getUser();
    let session_id = this.store.get("sessionId")
    this.attempted = this.totalAmount;
    this.ServiceID = this.generate_rand_num();
    let data = {
      order_info_uuid: this.store.get('orderId'),
      order_info_customer: user.user_information_uuid,
      master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      order_info_total_price: totalCartAmount,
      order_info_total_order_quantity: 0,
      order_info_total_discount: 0,
      available_redeemed_amount: 0,
      points_redeemed_amount: 0,
      master_coupon_uuid: null,
      master_subscription_uuid: null,
      subscription_mapping_uuid: null,
      used_subscription_quantity: 0,
      order_info_gst: 0, //Number(this.gst.toFixed(2)),
      // order_info_service_tax [OPTIONAL]
      order_info_grand_total: totalCartAmount,
      order_info_delivery_date: "",
      master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
      orders: this.cartItems_create_order,
      order_info_table_number: null,
      order_info_car_plate_number: null,
      "session_id": session_id,
      order_info_cutlery: 'FALSE',
      order_info_type: order_type,
      order_info_dinningmode: null,
      order_info_dinningtime: null,
      delivery_address: null,
      is_sap_order: "NO",
      user_name: this.name.user_name,
      country_uuid: this.store.getCountry().master_country_uuid,
      request_from: "EVENT_MOBILE_KIOSK",
      deducted: this.deducted,
      balance: this.balance,
      user_uuid: user_info && user_info.user_uuid ? user_info.user_uuid : "",
      customer_name: user_info && user_info.customer_name ? user_info.customer_name : "",
      user_information_email_address: this.name && this.name.email_address ? this.name.email_address : "",
      user_information_mobile_number: this.name && this.name.international_number ? this.name.international_number : "",
      is_user_claimed_order: this.isCoverageAccepted == 'true',
    }
    this.kiosk_apiService.audit_log("PAYMENT",data,"CREATE_ORDER_PAYLOAD");
    this.kiosk_apiService.logEvents("PAYMENT",data,"CREATE_ORDER_PAYLOAD");
    console.log('data', data);
    this.kiosk_apiService.create_kiosk_order(data).subscribe((res) => {
      if (res.statusCode == 200) {
        this.kiosk_apiService.audit_log("PAYMENT",data,"ORDER_CREATED");
        this.kiosk_apiService.logEvents("PAYMENT",data,"ORDER_CREATED")
        var testObject = {
          customer_name: res.aaData.user_information_fullname,
          user_uuid: res.aaData.user_information_uuid
        }
        this.store.set("user_details", testObject);
        this.thankyou();
        this.updateOrder(res, null, "QUEUED");
        this.store.set("orderId", res.aaData['order_info_uuid']);
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.store.setUserCart([]);
        this.store.remove("selectedItemsInCart");
        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.technical_error()
          }
        }
        if (res.aaData['master_restaurant_status'] == 'M_ON') {
          this.router.navigate([`home`]);
        } else {
        }
      } else {
        this.clickCounter = 0
        if (res?.userHasReachedEllaDrinkMaxLimit && res?.userHasReachedEllaDrinkMaxLimit == true) {
          this.maxLimitModal(res)
        } else {
          this.maintanenceOn(res);
        }
        this.kiosk_apiService.audit_log("PAYMENT",data,"ORDER_RES_ERR");
        this.kiosk_apiService.logEvents("PAYMENT",data,"ORDER_RES_ERR")
      }
    }, err => {
      this.clickCounter = 0
      this.kiosk_apiService.audit_log("PAYMENT",data,"ORDER_ERR");
        this.kiosk_apiService.logEvents("PAYMENT",data,"ORDER_ERR")
    })
  }
  
  updateOrder(orderData, paymentResponse, isPaymentFailed) {
    this.order_UUID = orderData.aaData.order_info_uuid
    this.isPaymentFailed = isPaymentFailed
    let posted_data = {
      data: {payment_type: "ELLA", used_quantity: 0, order_info_uuid:  this.order_UUID, payment_info_amount: 0, master_subscription_uuid: null, subscription_mapping_uuid: null}
      ,payment_type:"NA",card_type : "OTHERS"  
    }
  
    let data = {
      order_info_uuid: this.order_UUID,
      restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      payment_response: paymentResponse,
      payment_status: isPaymentFailed ? isPaymentFailed : '',
      suica_payment_details: posted_data,
    }
    this.kiosk_apiService.audit_log("PAYMENT", data, "Update_order_payload");
    this.kiosk_apiService.logEvents("PAYMENT", data, "Update_order_payload")
     this.kiosk_apiService.update_order(data).subscribe(res => {
      this.kiosk_apiService.audit_log("PAYMENT", data, "Update_order_res");
       this.kiosk_apiService.logEvents("PAYMENT", data, "Update_order_res")
     setTimeout(() => {
      this.store.remove("orderId");
      this.store.remove("totalAmount");
      localStorage.removeItem('sessionId');
      this.store.remove("totalCartAmount");
      this.store.setCart([]);
      this.store.remove("selectedItemsInCart");
      const sessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');
      this.store.set('sessionId', sessionId);
     }, 200);
  
     if (this.isPaymentFailed == 'QUEUED') {
       
      if(this.mobile_redirection_url !== null && this.mobile_redirection_url !== undefined){
        // setTimeout(() => {     
        //   console.log("logout started")
        //   this.store.hardlogout()
        //   location.href = this.mobile_redirection_url
        // }, 10000);
      }
      else{
        setTimeout(() => { 
          this.thankYou_alert.dismiss()    
          this.router.navigate(['home']);
        },1000);
      }
    }
      else if (!this.app_settings.enable_kiosk_print && this.isPaymentFailed == 'QUEUED') {
      }
    }, err => {
      this.clickCounter = 0
    }
    );
  }

  thankyou() {
    this.orderSubmittedSuccess = true;
  }
  checkAboutChubb() {
    if(this.mobile_redirection_url !== null && this.mobile_redirection_url !== undefined){
      setTimeout(() => {     
        console.log("logout started")
        this.store.hardlogout()
        location.href = this.mobile_redirection_url
      }, 100);
    }
    else{
      setTimeout(() => {   
        this.router.navigate(['home']);
      },100);
    }
  }
  
  async thankyou__() {
    this.thankYou_alert = await this.alertController.create({
      cssClass: 'thankyou-alert',
      header: 'Thank You',
      subHeader: ' Enjoy Your Coffee!',
      message: '<a><img src="https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/179109718420220727054946.gif"></a>',
      mode: 'ios',
    });
    setTimeout(() => {this.alertController.dismiss();}, 3000);
    await this.thankYou_alert.present();
  
  }

  generate_rand_num() {
    var rand: any;
    rand = Math.random();
    rand = rand * 10000000000;
    rand = Math.floor(rand);
    rand = '' + rand;
    rand = rand.substr(0, 8);
    return rand;
  }

  async technical_error(message?) {
    let errorMessage = '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>';
    if (message) {
      errorMessage = message;
    }
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: errorMessage,
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/home');
          }
        }
      ]
    });
    setTimeout(() => { this.router.navigateByUrl('/home'), this.alertController.dismiss(); }, 5000);
    (await alert).present();
  }

  async maintanenceOn(data) {
    this.store.setUserCart([]);
   
      let itemImage = "assets/image/bell.svg"
   
    let message = data.message
    let html = `<div class="custom-head">
      <span><img src="`+ itemImage + `"></span>
      <h2>Sorry</h2>
      <p>`+ message + `</p>
      </div>`
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal',
      mode: 'md',
      message: html,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.alertController.dismiss();
            
          }
        }
      ]
    });
    setTimeout(() => {
      this.alertController.dismiss();
    }, 5000);
    await alert.present();
  }
  async maxLimitModal(data) {
    this.store.setUserCart([]);
   
      let itemImage = "assets/image/bell.svg"
   
    let message = data.message
    let html = `<div class="custom-head">
      <span><img src="`+ itemImage + `"></span>
      <h2>Sorry</h2>
      <p>`+ message + `</p>
      </div>`
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal',
      mode: 'md',
      message: html,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            
            if(this.mobile_redirection_url !== null && this.mobile_redirection_url !== undefined){
              this.alertController.dismiss();
              setTimeout(() => {     
                console.log("logout started")
                this.store.hardlogout()
                location.href = this.mobile_redirection_url
              }, 100);
            }
            else{
              setTimeout(() => { 
                this.alertController.dismiss();  
                this.router.navigate(['home']);
              },100);
            }
            
          }
        }
      ]
    });
    setTimeout(() => {
      // this.alertController.dismiss();
      if(this.mobile_redirection_url !== null && this.mobile_redirection_url !== undefined){
        // this.alertController.dismiss();
        // setTimeout(() => {     
        //   console.log("logout started")
        //   this.store.hardlogout()
        //   location.href = this.mobile_redirection_url
        // }, 10000);
      }
      else{
        setTimeout(() => { 
          this.alertController.dismiss();  
          this.router.navigate(['home']);
        },1000);
      }
    }, 5000);
    await alert.present();
  }
  
}
