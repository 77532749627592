// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: "https://crownfooddelivery.trigma.in/web/v1",
  M_ON: `<img src="https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/31308108620220715064405.gif">`,
  ELLA_M_ON: `<img src="https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/201196039120220715064137.gif">`,
  STORE_CLOSED: `<img src="https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/155073420220715064303.gif">`,
  HOME_BANNNER: `<div><img src='assets/image/Cisco_banner.png'></div>`,

   // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyDpgYsUVFAqZOF5cLVgZIxj0fYknJtAlNk",
    authDomain: "staging-ella-app.firebaseapp.com",
    projectId: "staging-ella-app",
    storageBucket: "staging-ella-app.appspot.com",
    messagingSenderId: "893373876771",
    appId: "1:893373876771:web:25854ef09cd626899bbf96",
    measurementId: "G-XDKF9XRFRC"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
