import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalStoreService } from '../services/localstore.service';
import { AuditLogService } from '../services/audit-log.service';
import { KioskapiService } from '../services/kioskapi.service';



@Component({
  selector: 'app-claim-modal',
  templateUrl: './claim-modal.page.html',
  styleUrls: ['./claim-modal.page.scss'],
})
export class ClaimModalPage implements OnInit {
  selectedClaim = 'no';
  noRadioSelected = 'selected';
  yesRadioSelected = '';

  constructor(
    public modalCtrl: ModalController,
    private store: LocalStoreService,
    public alertController: AlertController,
    public modalController: ModalController,
    public auditlogServices: AuditLogService,
    private kiosk_apiService : KioskapiService,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss('dismiss');
    // this.store.setUserCart([]);
  }
  valueChanged(value) {
    if (value == 'yes') {
      this.noRadioSelected = '';
      this.yesRadioSelected = 'selected';
    } else if (value == 'no') {
      this.noRadioSelected = 'selected';
      this.yesRadioSelected = '';
    }
    this.selectedClaim = value;
  }

  checkout_orderCreate() {
    this.modalCtrl.dismiss(this.selectedClaim);
  }
}

