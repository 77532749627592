import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { API_URL } from '../common/api';
import { API_URL2 } from '../common/api';
import { SUICA_SERVER } from '../common/api';
import { LOCAL_PAYMENT_API_URL } from '../common/api';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from './localstore.service';

@Injectable({
  providedIn: 'root'
})
export class KioskauthService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(
      private httpClient: HttpClient,
      private router: Router,
      private route: ActivatedRoute,
      private store:LocalStoreService,
      public alertController: AlertController,
    ) {
          
  }

  hike_price(price){
    return  price+ ( price * 0.2);
  }

  
 
  is_logged_in(){
    let master_restaurant_uuid= localStorage.getItem("master_restaurant_uuid");
    let user_information_uuid= localStorage.getItem("user_information_uuid");

    if( master_restaurant_uuid !=null && master_restaurant_uuid !=undefined && user_information_uuid !=null && user_information_uuid !=undefined ){
        return true;
    }
    else{
        return false;
    }
  }  

  logout(){
      localStorage.clear();
      this.router.navigate(['/started']);
  }

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Accept-Language': this.store.getLanguageUuid()
      })
  }
    switch (method) {
      case 'get':
        return this.httpClient.get(API_URL + endPoint);
      case 'post':
        return this.httpClient.post(API_URL + endPoint, payload,options);
      case 'put':
        return this.httpClient.put(API_URL + endPoint, payload);
      case 'patch':
        return this.httpClient.patch(API_URL + endPoint, payload);
      case 'delete':
        return this.httpClient.delete(API_URL + endPoint, payload);
      default:
        return null;
    }
  }

  paymentApiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Accept-Language': this.store.getLanguageUuid()
      })
  }
    switch (method) {
      case 'get':
        return this.httpClient.get(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint);
      case 'post':
        return this.httpClient.post(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint, payload,options);
      case 'put':
        return this.httpClient.put(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint, payload);
      case 'patch':
        return this.httpClient.patch(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint, payload);
      case 'delete':
        return this.httpClient.delete(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint, payload);
      default:
        return null;
    }
  }

  apiReq2(method: string, endPoint: string, payload?: any, options: any = this.httpOptions): Observable<any> {
    console.log({ method, endPoint, payload });
    switch (method) {
      case 'get':
        return this.httpClient.get(API_URL2 + endPoint);
      case 'post':
        return this.httpClient.post(API_URL2 + endPoint, payload,options);
      case 'put':
        return this.httpClient.put(API_URL2 + endPoint, payload);
      case 'patch':
        return this.httpClient.patch(API_URL2 + endPoint, payload);
      case 'delete':
        return this.httpClient.delete(API_URL2 + endPoint, payload);
      default:
        return null;
    }
  }

  onError(message): void {
   console.log("SERVICE ERROR: ", message);
  }

  async isRestaurantOperational(){
    let restaurantDetails = this.store.getRestaurant();
    let param = {
          "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
      }
    this.apiReq('post', 'masterrestaurant/isrestaurantoperational', param)
      .subscribe(
          (res) => {
              if (res.statusCode == 200) {
                if(res.aaData == true) {
                  return true;
                } else {
                  this.isRestaurantOpenAlert();
                  return false;
                }  
              } 
          },
      );
  }

  async isRestaurantOpenAlert(){
    let close_restaurant_title = "Sorry";
    let close_restaurant_message = "Outside business hours.";
    // this.translate.get('ERRORS').subscribe((data:any)=> {
    //     if(data.close_restaurant_title){
    //       close_restaurant_title         = data.close_restaurant_title;
    //     }
    //     if(data.close_restaurant_message){
    //       close_restaurant_message         = data.close_restaurant_message;
    //     }
    // });
    const alert = await this.alertController.create({
          backdropDismiss: false,
          cssClass: 'my-custom-class',
          mode: 'md',
          header: close_restaurant_title,
          message: close_restaurant_message,
          buttons: [       
            {
              text: 'Back',
              handler: () => {
                this.alertController.dismiss();
                this.router.navigateByUrl('/home');
              }
            }, {
              text: '',
              cssClass: 'cross',
              handler: () => {
                this.alertController.dismiss();
                this.router.navigateByUrl('/home');
              }
            }
          ]
        });
        await alert.present();
        return false;
  }

  async itemNotAvailableAlert(){
    let item_not_available_title = "Sorry!";
    let item_not_available_message = "Item not available till tomorrow.";
    // this.translate.get('ERRORS').subscribe((data:any)=> {
    //   if(data.itm_not_available_title) {
    //     item_not_available_title = data.itm_not_available_title;
    //   }
    //   if(data.itm_not_available_message) {
    //     item_not_available_message = data.itm_not_available_message;
    //   }
    // });
    const alert = await this.alertController.create({
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      mode: 'md',
      header: item_not_available_title,
      message: item_not_available_message,
      buttons: [       
        {
          text: 'Back',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/home');
          }
        }, {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/home');
          }
        }
      ]
    });
    await alert.present();
    return false;
  }
}
